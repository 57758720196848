var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"A-L1s_GjBJa--iVJZpbch"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const { SENTRY_DSN_URI } = publicRuntimeConfig

import { currentEnv } from './sentry.common.config'

if (!!currentEnv) {
  Sentry.init({
    dsn:
      SENTRY_DSN_URI ||
      'https://b8edcf1136074238afd0b9ff337d24fc@o1353005.ingest.sentry.io/6662282',
    // Adjust this value in production, or use tracesSampler for greater control
    sampleRate: 1.0,
    tracesSampleRate: 0.2,
    // // example of using different rate on different pages
    // tracesSampler: sampleContext => {
    //   if (
    //     sampleContext?.location?.pathname?.includes('/communications/report')
    //   ) {
    //     return 0.75
    //   }
    //   return 0.4
    // },
    enabled: !!currentEnv,
    environment: currentEnv.sentryEnv,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
